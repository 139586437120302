.light--blue--color-shade--6 {
  background: #0065b7;
}

.brand--bg--color {
  background: #003399;
}

.brand--text--color {
  color: #003399 !important;
}

.light--blue--color {
  background: #dde2ec;
}

.in--active--blue--color {
  background: #7d98c9;
}

.black--color-text {
  color: #141b2f;
}

.white--color-text {
  color: #fafafa !important;
}

.blue--color--text {
  color: #003399;
}

.grey--color--text {
  color: #5c5c5c;
}

.green--color--text {
  color: #269f00;
}

.red--color--text {
  color: #c43a4b;
}

.black--color--00--text {
  color: #000000;
}

.digit--blue--color {
  color: #05c098;
}

.digit--red--color {
  color: #f5555f;
}

.bg--digit--blue--color {
  background: #05c098;
}

.bg--digit--red--color {
  background: #f5555f;
}

.white--background--color {
  background-color: #fafafa;
}

.black--background--color {
  background-color: #141b2f;
}

.black--color--00--bg {
  background: #000000 !important;
}

.blue--background--color {
  background-color: #003399;
}

.light--grey--color-shade--3 {
  background-color: #f0f0f0;
}

.light--blue--bg-color-shade--1 {
  background-color: #7d96c9;
}

.light--blue--bg-color-shade--2 {
  background-color: #c8d2e7;
}

.light--bg--color {
  background-image: linear-gradient(#86adfd, #486aae) !important;
}

.dark--blue--bg-color-shade--1 {
  background-color: #131b2e;
}

.bg-green-dark {
  background: #269f00;
}

.bg-red-dark {
  background: #C43A4B;
}

.brand--color--border {
  border: 1px solid #003399;
}

.brand--border--blue {
  border: 1px solid #003399;
}

.red--color--border {
  border: 1px solid #c43a4b;
}

.green--color--border {
  border: 1px solid #269f00;
}

.black--color--border {
  border: 1px solid #141b2f;
}

.white--color--border {
  border: 1px solid #fafafa;
}

.n-primary-black-bg {
  background: #001316;
}

.n-primary-cyan-bg {
  background: #75ffff;
}

.n-primary-orange-bg {
  background: #d16014;
}

.n-primary-white-bg {
  background: #fffaf7;
}

.n-primary-dark-orge-bg {
  background: #9e6240;
}

.n-primary-purple-bg {
  background: #776871;
}

.n-primary-green-bg {
  background: #339581;
}

.active--cyan--color {
  background: #75ffff;
  border: 1px solid #75ffff;
}

.in--active--cyan--color {
  background: #2d898b;
  border: 1px solid #2d898b;
}

.n-primary-black-txt {
  color: #001316;
}

.n-primary-black-txt-imp {
  color: #001316 !important;
}

.n-primary-cyan-txt {
  color: #75ffff;
}

.n-primary-orange-txt {
  color: #d16014;
}

.n-primary-white-txt {
  color: #fffaf7;
}

.n-grey-color-100-txt {
  color: #7f8686;
}

.n-primary-dark-orge-txt {
  color: #9e6240;
}

.n-primary-purple-txt {
  color: #776871;
}

.n-primary-green-txt {
  color: #339581;
}

.n-primary-cyan-border {
  border: 1px solid #75ffff;
}

.n-primary-white-border {
  border: 1px solid #fffaf7;
}

.neuePowerTrial-Regular {
  font-family: "neuePowerTrial-Regular";
}

.neuePowerTrial-Medium {
  font-family: "neuePowerTrial-Medium";
}

.canela-LightItalic {
  font-family: "canela-LightItalic";
}

.neueMontreal-Thin {
  font-family: "neueMontreal-Thin";
}

.neueMontreal-Book {
  font-family: "neueMontreal-Book";
}

.neueMontreal-Regular {
  font-family: "neueMontreal-Regular";
}

.neue-regular {
  font-family: "Neue-regular";
}

.Neue-bold {
  font-family: "Neue-bold";
}

@font-face {
  font-family: "PPNeueMontreal-Light";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Light.ttf");
}
@font-face {
  font-family: "Neue-light";
  src: url("../fonts/Neue-Power/NeuePowerTrial-Light.ttf");
}
@font-face {
  font-family: "Neue-regular";
  src: url("../fonts/Neue-Power/NeuePowerTrial-Regular.ttf");
}
@font-face {
  font-family: "Neue-bold";
  src: url("../fonts/Neue-Power/NeuePowerTrial-Bold.ttf");
}
@font-face {
  font-family: "Neue-montreal-medium";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Medium.otf");
}
@font-face {
  font-family: "Neue-montreal-regular";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Regular.otf");
}
@font-face {
  font-family: "Canela-RegularItalic";
  src: url("../fonts/canela-text-trial-cufonfonts/Canela-RegularItalic-Trial.otf");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "CanelaDeck-LightItalic";
  src: url("../fonts/canela-text-trial-cufonfonts/Canela-LightItalic-Trial.otf");
}
@font-face {
  font-family: "ppneuemontreal-thin";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Thin.otf");
}
@font-face {
  font-family: "PPNeueMontreal-Regular";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Regular.otf");
}
@font-face {
  font-family: "PPNeueMontreal-Medium";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Medium.ttf");
}
.roboto-sans-serif {
  font-family: "Roboto", sans-serif;
}

.pp-neue-montreal {
  font-family: "PP Neue Montreal", sans-serif;
}

.neue-power-sans-serif {
  font-family: "Neue Power", sans-serif;
}

.neue-bold {
  font-family: "Neue-bold";
}

.poppins-sans-serif {
  font-family: "Poppins", sans-serif;
}

.new_buttons_theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 0px;
  cursor: pointer;
  height: 100%;
}

.new_buttons_theme_a {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 1px solid #75ffff;
  cursor: pointer;
}

.font-12 {
  font-size: 0.75rem !important;
  line-height: 0.875rem !important;
}

.font-14 {
  font-size: 0.875rem;
  line-height: 1rem;
}

.font-wt-300 {
  font-weight: 300;
}

.font-wt-400 {
  font-weight: 400;
}

.font-wt-500 {
  font-weight: 500;
}

.font-wt-600 {
  font-weight: 600;
}

.font-wt-700 {
  font-weight: 700;
}

.banner-main-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.25rem;
  font-weight: 400;
  line-height: 7.5rem;
  text-align: center;
  color: #fff;
}
.banner-main-heading span {
  font-family: "canela-LightItalic";
  font-weight: 300;
}

.common-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.75rem;
}
.common-heading span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 300;
}

.big-heading {
  font-family: "neuePowerTrial-Medium";
  font-size: 4rem;
  font-weight: 400;
  line-height: 4.75rem;
  text-align: left;
}

.font-70 {
  font-family: "neuePowerTrial-Regular";
  font-size: 4.375rem;
  font-weight: 500;
  line-height: 5.25rem;
  text-align: center;
}

.buttons__theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.9625vw;
  font-weight: 500;
}

.heading--text {
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 4.688rem;
}

.sub-heading--text {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
}
.sub-heading--text span {
  color: #003399;
}

.sub-heading--text-16 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-weight: 400;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

img {
  width: 100%;
}

body {
  background: #001316;
}

.error-part {
  position: relative;
  float: left;
  width: 100%;
  background: #001316;
}
.error-part .web-view {
  display: block;
}
.error-part .mob-view {
  display: none;
}
.error-part .text-btn-part {
  position: absolute;
  bottom: 27%;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
}
.error-part .text-btn-part h3 {
  font-family: "neuePowerTrial-Regular";
  font-size: 54px;
  font-weight: 700;
  line-height: 64.8px;
  margin-bottom: 2rem;
  color: #fffaf7;
}
.error-part .text-btn-part p {
  color: #fffaf7;
  font-size: 24px;
}
.error-part .text-btn-part a {
  font-family: "neuePowerTrial-Regular";
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  background: #75ffff;
  border-radius: 50px;
  color: #001316;
  padding: 10px 42px;
  margin: auto;
  display: block;
  width: -moz-max-content;
  width: max-content;
  margin-top: 1rem;
}

@media (max-width: 900px) {
  .error-part .text-btn-part {
    bottom: 30%;
  }
  .error-part .text-btn-part h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 1.625rem;
  }
  .error-part .text-btn-part a {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 50px;
    padding: 14px 48px;
  }
  .error-part .web-view {
    display: none;
  }
  .error-part .mob-view {
    display: block;
  }
}/*# sourceMappingURL=not-found-page.css.map */
